@import "src/app/styles/assets/scss/colors";

.questions_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;

  .content_header {
    color: $black;
    font-size: 1.6rem;
    font-weight: 800;
    line-height: 2.2rem;
    margin-bottom: 1.2rem;
  }

  > :not(:last-child):not(:first-child) {
    margin-bottom: .8rem;
  }
}