@import 'app/styles/assets/scss/colors';

.wrapper {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: $grayLightrest1;

  .progress_bar_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: calc(0.4rem + var(--sait));
    padding: 0 1.6rem;
    z-index: 10001;

    .progress_holder {
      display: flex;
      padding: 0;
      margin: 0 0 1.6rem 0;

      > *:not(:last-child) {
        margin-right: 0.4rem;
      }

      li {
        flex: 1;
        background-color: $grayLight;
        height: 0.2rem;
        position: relative;
        overflow: hidden;
        border-radius: 5rem;

        &::after {
          content: '';
          position: absolute;
          border-radius: 5rem;
          top: 0;
          left: 0;
          height: 100%;
          width: 0;
          background-color: $grayLight;
          transition: width 0.1s linear;
        }
      }
    }

    .close_button {
      display: flex;
      justify-content: flex-end;
      color: $white;
    }
  }

  .content {
    padding: 0;
    margin: 0;
    display: flex;
    transition: transform 0.3s ease;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: $grayLightrest1;

    .item {
      flex: 0 0 100%;
      width: 100%;

      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
  }
}
