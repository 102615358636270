@import "src/app/styles/assets/scss/variables";
@import "src/app/styles/assets/scss/colors";

.content {
  display: grid;
  grid-gap: 2.4rem;
  grid-row: listTop / listBottom;
}

.footer {
  display: grid;
  grid-gap: 1.2rem;

  .footer_text {
    color: $grayDark;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.9rem;
  }

  .skeleton_button {
    display: flex;
  }
}

.remove_buttons_holder {
  display: grid;
  grid-gap: .8rem;
}