@import "app/styles/assets/scss/variables";
@import "app/styles/assets/scss/colors";

.tabs {
  ul {
    margin: 0 !important;
  }
}

.empty_credits {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 3.2rem);

  .empty_icon {
    font-family: $iconFont2;
    font-size: 4rem;
    line-height: 1;
    color: $gray;
    margin-bottom: 1.2rem;
  }

  .text_block {
    text-align: center;
    display: grid;
    grid-gap: .4rem;
    margin-bottom: 1.2rem;

    .text_block_header {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.2rem;
      color: $black;
    }

    .text_block_description {
      color: $grayDark;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.9rem;
    }
  }
}

.footer {
  display: grid;

  color: $grayDark;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.9rem;
}