@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.wrapper {
  display: grid;
  width: 100%;

  &.has_space {
    margin-bottom: 2.4rem;
  }

  .label_holder {
    display: flex;
    margin-bottom: .8rem;

    label {
      color: $black;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: -0.14px;
      height: 1.8rem;
    }
  }

  .input_holder {
    display: flex;
    align-items: center;
    padding: 1.6rem;
    border-radius: 16px;
    background-color: $grayLightrest1;
    pointer-events: auto;

    &.disabled {
      opacity: 0.4;
    }

    &.loading {
      pointer-events: none;
    }

    &.error {
      background-color: $errorLightrest1;
    }

    .icon_holder {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      font-family: $iconFont2;
      color: $gray;
      padding-right: 1.2rem;
      font-size: 2rem;
      line-height: 1;
    }

    .left_text {
      color: $black;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: -0.16px;
      padding-right: .8rem;
    }

    .map_value {
      color: $black;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: -0.16px;
      min-height: 2.1rem;
      flex: 1 1 0;

      &.placeholder {
        color: $gray !important;
      }
    }

    .input {
      outline: none;
      background-color: transparent;
      border: none;
      width: 100%;
      padding: 0;
      font-family: $fontTilda !important;

      color: $black;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.2rem;
      letter-spacing: -0.16px;

      &::placeholder {
        color: $gray;
      }

      &.error {
        color: $errorLight;
      }
    }

    .dropdown_holder {
      display: flex;
      align-items: center;
      padding-left: 1.2rem;

      .dropdown_value {
        height: 2.1rem;
        color: $black;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: -0.16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .dropdown_icon {
        font-size: 2rem;
        line-height: 1;
        font-family: $iconFont2;
        color: $gray;
        padding-left: .8rem;
      }
    }
  }

  .subtext {
    margin-top: .8rem;
    color: $grayDark;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 130%;
  }

  .error_message {
    margin-top: .8rem;
    color: $error;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.7rem;
  }
}

.input_loading {
  width: 1.6rem;
  height: 1.6rem;

  svg {
    g {
      g {
        g {
          path {
            stroke: $grayDarker;
          }
        }
      }
    }
  }
}