@import "app/styles/assets/scss/colors";
@import "app/styles/assets/scss/variables";

.base {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .8rem 1.2rem;
  border-radius: 3rem;
  background-color: $grayLightrest1;
  cursor: pointer;
  margin-right: .8rem;

  color: $black;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.7rem;
  white-space: nowrap;
}

.tab {
  position: relative;
  @extend .base;

  &.secondary {
    background-color: $white;
  }

  &.is_loading {
    pointer-events: none;
  }
}

.tab_active {
  @extend .base;
  background-color: $black;
}

.text {
  display: flex;
  color: $black;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.7rem;

  &.is_loading {
    opacity: 0;
  }
}

.text_active {
  @extend .text;
  color: $white;
}