@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.icon {
  font-family: $iconFont2;
  color: $gray;
  font-size: 2rem;
  line-height: 1;
}

.rounded_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;
  border-radius: 50rem;
  width: 1.6rem;
  height: 1.6rem;

  &.remove_toast {
    background-color: rgba($grayDarker, .4) !important;
  }

  &.default {
    background-color: $grayLightrest1;
  }

  &.success {
    background-color: $successLightrest1;

    > * {
      color: $successDark;
    }
  }

  &.black {
    background-color: $white;

    > * {
      color: $black;
      font-size: 2rem;
    }
  }

  &.error {
    background-color: $errorLightrest1;

    > * {
      color: $errorDark;
    }
  }
}

.rounded_icon {
  font-family: $iconFont2;
  color: $grayDark;
  font-size: 1.6rem;
  line-height: 1;
}