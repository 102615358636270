@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.questions_wrapper {
  display: grid;
  grid-gap: 1.2rem;

  .questions_header {
    display: flex;
    justify-content: space-between;

    color: $black;
    font-size: 1.6rem;
    font-weight: 800;
    line-height: 2.2rem;

    button {
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
      margin: 0;
      color: $primary;
      font-size: 1.2rem;
      line-height: 1.7rem;
      font-weight: 600;
    }
  }
}

.questions_list {
  display: grid;
  grid-gap: .8rem;
  margin-bottom: 2.4rem;
}

.catalogue_form_wrapper {
  display: grid;
  grid-template-columns: 2.4rem 1fr 1.6rem;
  grid-gap: 1.2rem;
  align-items: center;
  margin-bottom: 2.4rem;

  padding: .8rem 1.6rem;
  background-color: $grayLightrest1;
  border-radius: $borderRadius;
}

.catalogue_form_text {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2rem 1fr;
  grid-gap: .4rem;

  div:nth-child(1) {
    color: $black;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.9rem;
  }

  div:nth-child(2) {
    color: $grayDark;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.7rem;
  }
}