.list {
  padding: 0 1.6rem;
  margin: 0 -1.6rem;
  display: flex;
  overflow-x: auto;
  //margin: 0;

  -ms-overflow-style: none;
  scrollbar-width: none;

  list-style: none;

  &::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  li:not(:last-child) {
    margin-right: .8rem;
  }
}