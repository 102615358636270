@import "src/app/styles/assets/scss/colors";

.content {
  padding: 2.4rem 1.6rem;
  padding-bottom: calc(1.2rem + 89px);
  display: grid;
  grid-gap: 2.4rem;

  > div {
    display: grid;
    grid-gap: 1.2rem;
  }

  ul {
    margin: 0;
  }

  .skeleton_item {
    display: grid;
    grid-template-columns: 1fr 2rem;
    align-items: center;
    grid-gap: 1.2rem;
    margin-bottom: 2.4rem;
  }

  .skeleton_popular {
    display: flex;
    flex-wrap: wrap;

    > * {
      margin: 0 .8rem .8rem 0;
    }
  }
}

.footer {
  position: fixed;
  bottom: var(--saib);
  padding: 1.2rem 1.6rem 0 1.6rem;
  width: calc(100% - 3.2rem);
  background-color: $white;
  z-index: 100;
}