@import 'app/styles/assets/scss/variables';
@import 'app/styles/assets/scss/colors';
@import 'app/styles/assets/scss/cropper';
@import 'app/styles/assets/scss/spacing';
@import 'app/styles/assets/scss/inputRange';
@import 'app/styles/assets/scss/text';
@import './assets/fonts/tildaSans/fontface';
@import './assets/fonts/vocfont2/fontface';
@import './assets/scss/photoswipe';

html {
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

:root {
  --sait: var(--android-safe-area-inset-top, env(safe-area-inset-top));
  --saib: var(--android-safe-area-inset-bottom, env(safe-area-inset-bottom));
}

* {
  font-family: $fontTilda;
}

body {
  touch-action: none;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  padding-top: var(--sait) !important;
  padding-right: env(safe-area-inset-right) !important;
  padding-bottom: var(--saib) !important;
  padding-left: env(safe-area-inset-left) !important;
}

html,
body {
  font-family: $fontTilda;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  position: relative;
  box-sizing: border-box;
  overscroll-behavior: none;
  touch-action: manipulation;
  overflow: hidden;
}

.no_touch_action {
  touch-action: none !important;
  overflow: hidden;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.react-modal-sheet-container {
  border-top-left-radius: 2.4rem !important;
  border-top-right-radius: 2.4rem !important;
  box-shadow: none !important;
}

div {
  -moz-user-select: none;
  user-select: none;
}

.react-modal-sheet-header {
  position: absolute !important;
  top: -0.8rem !important;
  height: 0 !important;

  span {
    background-color: $grayLight !important;
    transform: translateX(2px) rotate(0) !important;
    width: 2rem !important;
  }

  span:nth-child(1) {
    border-radius: 0.2rem 0 0 0.2rem !important;
  }

  span:nth-child(2) {
    border-radius: 0 0.2rem 0.2rem 0 !important;
  }
}

.react-modal-sheet-backdrop {
  background-color: rgba(37, 39, 66, 0.4) !important;
}

.react-loading-skeleton {
  z-index: 1 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[readonly]::selection {
  background-color: transparent;
}

input[readonly]::-moz-selection {
  background-color: transparent;
}

a {
  color: $primary;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  outline: none !important;
}

textarea {
  -webkit-user-select: text !important;
  user-select: text !important;
}

ul {
  list-style: none;
  padding: 0;
}

.underline {
  color: $grayDark;
  font-family: $fontTilda;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.14px;
  text-decoration: underline;
}

.inApp_buttons {
  display: grid;
  grid-gap: 0.8rem;
}
