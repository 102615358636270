@import "app/styles/assets/scss/colors";
@import "app/styles/assets/scss/variables";

.wrapper {
  padding: 1.6rem;
  background-color: $grayLightrest1;
  gap: 1.2rem;
  border-radius: $borderRadius;
  display: grid;
  grid-template-columns:
    [leftContentStart] 9.9rem [leftContentEnd] 1.2rem
    [rightContentStart] 1fr [rightContentEnd];

  .left_content {
    grid-column: leftContentStart / leftContentEnd;
    display: flex;
    flex-direction: column;
    width: 9.9rem;

    .left_content_count {
      color: $grayDark;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.4rem;
    }

    .left_content_rating {
      display: flex;
      align-items: center;

      .star {
        font-family: $iconFont2;
        font-size: 3.2rem;
        line-height: 1;
        color: $warning;
        margin-right: .8rem;
      }

      .rating {
        color: $black;
        font-size: 4rem;
        font-weight: 800;
        line-height: 5.6rem;
      }
    }
  }

  .stars_indicator {
    grid-column: rightContentStart / rightContentEnd;

    .rating_item_wrapper {
      display: grid;
      grid-template-columns:
     [starsStart] auto [starsEnd] .8rem
     [progressBarStart] 1fr [progressBarEnd] .8rem
     [countStart] auto [countEnd];
      align-items: center;

      .stars_container {
        display: flex;
        align-items: center;
        grid-column: starsStart / starsEnd;

        .rating_item_count {
          width: .6rem;
          color: $grayDark;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.4rem;
        }

        div:nth-child(2) {
          font-family: $iconFont2;
          font-size: 1rem;
          line-height: 1;
          color: $grayLighter;
          margin: 0 .4rem;
        }
      }

      .progress_container {
        position: relative;
        height: .4rem;
        border-radius: $borderRadius;
        background-color: $grayLighter;
        grid-column: progressBarStart / progressBarEnd;

        .progress_bar {
          position: absolute;
          height: .4rem;
          border-radius: $borderRadius;
          background-color: $warning;
        }
      }

      .count {
        color: $grayDark;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.4rem;
        grid-column: countStart / countEnd;
      }
    }
  }
}