@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.wrapper {
  display: grid;
  grid-gap: 1.2rem;
  color: $black;

  .header {
    font-size: 1.6rem;
    font-weight: 800;
    line-height: 2.2rem;
  }

  .tabs {
    margin-bottom: 0;

    ul {
      margin: 0;
    }
  }

  .empty {
    color: $grayDark;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.9rem;
  }
}