@import "src/app/styles/assets/scss/colors";

.price_subtext {
  margin-top: .8rem;
  display: flex;
  align-items: center;

  span {
    color: $grayDark;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 130%;
  }

  span:nth-child(2) {
    color: $primary;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 130%;
    margin-left: .2rem;
  }
}

.payment_info_subtext {
  display: grid;

  span {
    color: $grayDark;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.14px;
  }
}

.units_holder {
  display: grid;
  grid-gap: 2.4rem;
  padding: 0 1.6rem 2.4rem 1.6rem;
}