@import "src/app/styles/assets/scss/colors";

.search {
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-gap: 1.2rem;
  align-items: center;
  width: 100%;

  > span {
    color: $black;
  }
}

.page_wrapper {
  display: grid;
}