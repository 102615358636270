@import "app/styles/assets/scss/colors";

.tabs {
  ul {
    margin: 0;
  }
}

.empty_prices {
  color: $grayDark;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.9rem;
}

.prices_block {
  > *:not(:last-child) {
    margin-bottom: 1.2rem;
  }
}