@import "src/app/styles/assets/scss/variables";

.content {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-bottom: 2.4rem;
}

.inApp_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  justify-content: center;
  grid-gap: 2.4rem;
  padding: 0 1.6rem 0 1.6rem;
}

.inApp_buttons {
  display: grid;
  width: 100%;
  grid-gap: .8rem;
}