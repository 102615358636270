@import "src/app/styles/assets/scss/colors";

.header_block_wrapper {

  .header_holder {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header_title {
      overflow: hidden;
      color: $black;
      text-overflow: ellipsis;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.1rem;
      letter-spacing: -0.16px;
    }

    .header_children_margin {
      margin-left: .4rem;
    }
  }

  .description_holder {
    display: grid;
    margin-top: .4rem;

    .description_text {
      display: inline-flex;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.8rem;
      letter-spacing: -0.14px;
      color: $grayDark;
    }
  }

  .description_link {
    display: inline-flex;
    color: $primary;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    text-decoration-line: underline;
    margin-top: .4rem;
  }
}