@import "app/styles/assets/scss/colors";

.action_panel_list {
  margin: 0 -1.6rem;
  padding: 0 1.6rem 0 0;
  display: flex;
  justify-content: flex-start;

  flex-wrap: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  list-style: none;

  li:not(:last-child) {
    margin-right: .8rem;
  }

  &::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .skeleton_item {
    width: 100%;

    .skeleton {
      > * {
        border-radius: 0 1.2rem 1.2rem 0 !important;
      }
    }
  }

  .panel_item {
    display: flex;
    align-items: center;
    padding: 1.6rem;

    max-width: 26.7rem;

    &.has_one_item {
      min-width: calc(100% - 3.2rem);
    }

    > *:not(:last-child) {
      margin-right: 1.2rem;
    }

    &.create_order, &.create_vacancy, &.stories {
      background-color: $black;
      border-radius: 0 1.6rem 1.6rem 0;
    }

    &.order, &.bids, &.pay, &.vacancy, &.guarantee {
      background-color: $grayLightrest1;
      border-radius: 1.6rem;
    }

    .image_item_holder {
      width: 4rem;
      height: 4rem;

      img {
        width: 100%;
        height: 100%;
        border-radius: 32rem;
        object-fit: cover;
      }
    }

    .list_text_holder {
      max-width: 100%;
      flex: 1 1 auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      > *:not(:last-child) {
        margin-bottom: .4rem;
      }

      &.has_prefix {
        max-width: calc(100% - 8.4rem);
      }

      &.has_one_item {
        flex: 1 1 0;
      }

      &.create_order, &.create_vacancy, &.stories {
        color: $white;

        .list_text_title {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          font-weight: 500;
          font-size: 1.6rem;
          line-height: 2.1rem;
        }

        .list_text_subtitle {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.8rem;
        }
      }

      &.order, &.bids, &.pay, &.vacancy, &.guarantee {
        .list_text_title {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 2.1rem;
          color: $black;
        }

        .list_text_subtitle {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.8rem;
          color: $grayDark;
        }
      }
    }

    .item_icon_suffix {
      &.create_order, &.create_vacancy, &.stories {
        color: $white;
      }

      &.order, &.bids, &.pay, &.vacancy, &.guarantee {
        color: $gray;
      }
    }
  }
}