.ad_fox_container {
    margin: 0 -1.6rem;
  
    > div  {
      height: 100% !important;
    margin-bottom: 2.4rem;
    }
  
    a, img {
      width: 100% !important;
      max-width: 100% !important;
    }
  }