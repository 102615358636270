@import "src/app/styles/assets/scss/colors";

.invite_count_wrapper {
  display: grid;
  background-color: $grayLightrest1;
  border-radius: 1.2rem;
  padding: 1.2rem;
  margin-bottom: 2.4rem;

  .invite_count_header {
    color: $black;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.9rem;
  }

  .invite_count_description {
    color: $grayDark;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.7rem;
  }

  .indicator_container {
    position: relative;
    height: .4rem;
    border-radius: 1.2rem;
    background-color: $grayLighter;
    margin: .8rem 0;

    .indicator_bar {
      position: absolute;
      height: .4rem;
      border-radius: 1.2rem;
      background-color: $primary;
    }
  }
}