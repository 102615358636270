@import "src/app/styles/assets/scss/colors";

.profile_info {
  display: grid;
  grid-gap: 1.6rem;
  align-items: center;
  justify-content: center;
  background-color: $grayLightrest1;
  margin: -2.4rem -1.6rem 0 -1.6rem;
  padding: 2.4rem 0;

  .avatar_holder {
    width: 12rem;
    height: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32rem;
    border: 1px solid $grayLightrest1;
    background-color: $grayLightrest2;
    align-self: center;
    justify-self: center;

    .avatar {
      font-size: 6.4rem;
      line-height: 1;
      color: $grayLight;
    }
  }

  .name_holder {
    display: grid;
    grid-gap: .4rem;

    .skeleton_rating {
      display: grid;
      grid-template-columns: repeat(2, 6rem);
      grid-gap: .2rem;
      align-items: center;
      justify-content: center;
    }

    .name {
      overflow: hidden;
      color: $black;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 2rem;
      font-weight: 800;
      line-height: 2.8rem;
    }

    .info_content_rating {
      display: flex;
      justify-content: center;
      align-items: center;

      span:nth-child(1) {
        color: $warning;
      }

      .newbie {
        color: $black;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.7rem;
      }

      .rating {
        display: flex;
        align-items: center;

        span:nth-child(1) {
          color: $black;
          margin-right: .2rem;
        }

        color: $gray;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.7rem;
      }
    }
  }
}