@import "src/app/styles/assets/scss/colors";

.phone_holder {
  margin-top: -1.2rem;

  .phone_prefix {
    display: flex;
    align-items: center;

    img {
      margin-right: .4rem;
      width: 2rem;
      height: 2rem;
    }

    div {
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.1rem;
      letter-spacing: -0.16px;
    }
  }
}

.client_assistant_info {
  display: grid;

  span {
    color: $grayDark;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.14px;
  }
}

.inApp_buttons {
  display: grid;
  grid-gap: 2.4rem;
  padding: 0 1.6rem;

  .addresses_holder {
    display: grid;
    grid-gap: 1.6rem;
    list-style: none;
    padding: 0;
    margin: 0;

    .input_count {
      margin-right: 1.2rem;
      display: inline-flex;
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 36rem;
      background-color: $grayDark;

      color: $white;
      text-align: center;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.6rem;
    }
  }
}