@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.content {
  display: grid;
  grid-gap: 2.4rem;
  background-color: $white;
  margin: 0 -1.6rem;
  border-radius: 2.4rem 2.4rem 0 0;
  margin-bottom: -2.4rem;
  padding: 2.4rem 1.6rem 0 1.6rem;
  margin: 0 -1.6rem;
}

.client_view_wrapper {
  background-color: $grayLightrest1;
  overflow-y: auto;
  padding: 2.4rem 1.6rem 0 1.6rem;
}