@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.content {
  display: grid;
  flex-direction: column;
  grid-gap: 2.4rem;
  margin-bottom: 2.4rem;
}


.gender_inApp {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 0 1.6rem 2.4rem 1.6rem;
}

.gender_inApp_items {
  display: grid;
  grid-gap: 2.4rem;
  touch-action: none;
}