@import "src/app/styles/assets/scss/colors";

.wrapper {
  display: grid;
  grid-template-columns: 2.4rem 1fr 3.6rem;
  grid-gap: 1.2rem;
  align-items: center;

  .connect_icon {
    color: $gray;
  }

  .disable_send_icon {
    @extend .connect_icon;
    padding: .6rem;
  }

  .send_icon {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 5rem;
    background-color: $black;
    //font-family: "Naimi-icons";
    font-size: 1.4rem;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
  }

  .send_icon_loading {
    width: 1.4rem;
    height: 1.4rem;
    justify-self: center;
  }
}