@import "src/app/styles/assets/scss/colors";

.popular_tags_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  max-height: 13.2rem;
  overflow: hidden;

  .popular_tag_item {
    display: inline-flex;
    background-color: $grayLightrest1;
    padding: 1rem 1.4rem;
    border-radius: 3rem;
    color: $black;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0;
    margin-right: .8rem;
    margin-top: .8rem;
    align-items: center;

    .remove_icon {
      font-size: 1.6rem;
      color: $black;
      margin-left: .8rem;
    }
  }
}