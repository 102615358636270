@import "src/app/styles/assets/scss/colors";

.promo_block {
  display: flex;
  width: 100%;

  img {
    background-color: $grayLightrest1;
    width: 100%;
    border-radius: 1.6rem;
    object-fit: cover;
  }
}