@import "app/styles/assets/scss/colors";
@import "app/styles/assets/scss/variables";

.schedule_item_wrapper {
  border-radius: $borderRadius;
  padding: 1.6rem;
  display: grid;
  grid-gap: .4rem;

  &.paid {
    background-color: $successLightrest1;
  }

  &.not_paid {
    background-color: $grayLightrest1;
  }

  &.error {
    background-color: $errorLightrest1;
  }

  .date {
    color: $grayDark;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.9rem;
  }

  .price {
    color: $black;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.8rem;
  }

  .state {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.7rem;

    &.paid {
      color: $successDark;
    }

    &.not_paid {
      color: $grayDark;
    }

    &.error {
      color: $black;
    }
  }
}