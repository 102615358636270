@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.content {
  display: grid;
  grid-gap: 2.4rem;
  margin-bottom: 2.4rem;

  .content_illustration {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16rem;
  }
}

.inApp_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  justify-content: center;
  grid-gap: 2.4rem;
  padding: 0 1.6rem 0 1.6rem;
}

.inApp_buttons {
  display: grid;
  width: 100%;
  grid-gap: .8rem;
}

.inProgressing_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 1.6rem);
  width: calc(100vw - 3.2rem);
  padding: .8rem 1.6rem;
}

.inProgressing_icon_holder {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.2rem;
}

.inProgressing_icon {
  font-family: $iconFont2;
  font-size: 4rem;
  line-height: 1;
  color: $gray;
}

.inProgressing_text {
  display: grid;
  grid-gap: .4rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.2rem;
}

.inProgressing_text_title {
  text-align: center;
  color: $black;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
}

.inProgressing_text_subtitle {
  color: $grayDark;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.9rem;
}