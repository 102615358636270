@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  touch-action: none !important;
}

.img_loading {
  visibility: hidden;
}

.loading_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header {
  width: 100%;
}

.settings {
  display: flex;
  align-items: center;
  gap: .8rem;
  padding: 0 1.6rem;
  width: calc(100vw - 3.2rem);
  opacity: 1;
  position: absolute;
  top: calc(var(--sait) + 7rem);
}

.settings_input_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: .8rem;
  flex: 1 1 0;
  margin-bottom: 1.6rem;

  color: $grayLightrest1;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.9rem;
}

.rotate_button {
  outline: none;
  border: none;
  align-self: flex-end;

  padding: 1rem;
  border-radius: 50rem;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    justify-content: center;
    align-items: center;
    font-family: $iconFont2;
    font-size: 2rem;
    line-height: 1;
    color: $black;
  }
}

.inApp_wrapper {
  position: absolute;
  bottom: var(--saib);
}

.inApp {
  border-radius: 2.4rem 2.4rem 0 0;
  display: grid;
  flex-direction: column;
  grid-gap: 2.4rem;
  background-color: $white;
  padding: 2.4rem 1.6rem 0 1.6rem;
  opacity: 1;


  div {
    div {
      font-size: 1.4rem !important;
      line-height: 1.9rem !important;
    }
  }
}

.inApp_before {
  position: absolute;
  top: -.8rem;
  width: 4rem;
  left: 50%;
  height: .4rem;
  transform: translateX(-50%);
  border-radius: .2rem;
  background-color: $grayLight;
}

.fail_inApp {
  display: grid;
  justify-content: center;
  padding: 0 1.6rem 0 1.6rem;
  grid-gap: 2.4rem;
}

.fail_inApp_image {
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
}