@import "app/styles/assets/scss/colors";

.tabs {
  ul {
    margin: 0 !important;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  grid-gap: .8rem;
  justify-content: start;

  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;

    .media_edit {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;

      div:first-child {
        z-index: 3;
        position: absolute;
        bottom: .8rem;
        right: .8rem;
      }
    }

    img {
      height: 100% !important;
      margin: 0 auto;
    }
  }
}

.skeleton_photo {
  aspect-ratio: 1 / 1;
}

.edit_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:first-child {
    display: flex;
    justify-content: flex-start;
  }

  div:last-child {
    display: flex;
    justify-content: flex-end;
  }
}

.empty_text {
  color: $grayDark;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9rem;
}

.innApp_buttons {
  display: grid;
  grid-gap: .8rem;
}