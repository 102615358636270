@import "src/app/styles/assets/scss/colors";

.list_item {
  display: grid;
  grid-template-columns: 1fr 2rem;
  grid-gap: 1.2rem;
  margin-bottom: 2.4rem;
  align-items: center;

  .item_content {
    display: grid;
    grid-gap: .4rem;

    .item_content_title {
      display: inline-flex;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.1rem;
      letter-spacing: -0.2px;
      color: $black;

      display: -moz-box !important;
      -moz-box-orient: vertical;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .item_content_subtitle {
      display: inline-flex;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.8rem;
      letter-spacing: -0.1px;

      &.service, &.vacancy {
        color: $successDark;
      }

      &.ultra {
        color: $errorDark;
      }
    }
  }
}
