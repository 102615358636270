@import "src/app/styles/assets/scss/colors";

.wrapper {
  display: grid;


  .header {
    .header_left {
      display: grid;
      grid-gap: .4rem;

      .header_left_name {
        color: $black;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.2rem;
      }

      .header_left_price {
        color: $grayDark;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.9rem;
      }
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .icon {
      transform: rotate(0deg);

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  &.open {
    grid-template-rows: 1fr;
  }

  .content {
    overflow: hidden;
    transition: max-height .4s cubic-bezier(0.4, 0, 0.2, 1);

    .content_nested {
      margin-top: 1.2rem;
      display: grid;
      grid-gap: 1.2rem;

      .nested_price_item {
        display: grid;
        grid-gap: .4rem;
        color: $black;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.9rem;

        span:nth-child(2) {
          color: $grayDark;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 1.9rem;
        }
      }
    }
  }
}