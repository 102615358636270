@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.current_tariff_wrapper {
  padding: 1.6rem;
  display: grid;
  grid-gap: 1.2rem;
  border-radius: $borderRadius;

  .current_tariff_header {
    display: grid;

    div:nth-child(1) {
      color: $black;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.2rem;
    }

    div:nth-child(2) {
      color: $grayDark;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.7rem;
    }
  }

  .current_tariff_description {
    color: $black;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.9rem;
  }

  .current_tariff_tabs {
    display: flex;
    justify-content: flex-start;
    min-height: 3.4rem;

    flex-wrap: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
      height: 0;
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent !important;
    }
  }
}