.footer {
  display: grid;
  grid-gap: .8rem;
}

.content {
  display: grid;
  grid-gap: .8rem;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: max-content;
  align-self: flex-start;
  margin-bottom: 2.4rem;
}