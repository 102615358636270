@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.wrapper {
  //position: absolute;
  //top: 0;
  //left: 0;
  //right: 0;
  //bottom: 0;
  //z-index: 3;
  width: 100%;
  position: fixed;
  visibility: visible;
  inset:0;
  background-color: $grayLighter;
  margin-bottom: var(--saib);

  .marker_holder {
    position: absolute;
    top: -6.6rem;
    left: -2.1rem;
  }

  .shadow_holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    padding-top: var(--sait);
    height: 7rem;
    width: 100%;
    background: linear-gradient(0deg, rgba(43, 43, 43, 0.00) 0%, rgba(43, 43, 43, 0.24) 50%, rgba(43, 43, 43, 0.60) 100%);

    .header_content_holder {
      position: relative;

      .city_select {
        background-color: $white;
        position: absolute;
        top: 1.7rem;
        left: 1.25rem;
        padding: 1rem 1.4rem;
        border-radius: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;
        box-shadow: 0 8px 16px 0 rgba(43, 43, 43, 0.20);

        .city_select_text {
          margin-right: .8rem;
          color: $black;
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 130%;
        }
      }

      .close_button {
        position: absolute;
        top: 2.5rem;
        right: 1.6rem;
        width: 2rem;
        height: 2rem;
        font-family: $iconFont2;
        font-size: 2rem;
        line-height: 1;
        color: $black;
        z-index: 3;
        outline: none;
        border: none;
        background-color: transparent;
        padding: 0;
      }
    }
  }

  .search {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    background-color: $white;
    border-radius: 2.4rem 2.4rem 0 0;
    padding: 2.4rem 1.6rem 0 1.6rem;

    .search_wrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .geolocation_button {
        position: absolute;
        right: 0;
        top: -10rem;
        padding: 1.4rem;
        z-index: 3;
        border-radius: 50rem;
        background-color: $white;
        box-shadow: 0 2px 4px 0 rgba(37, 39, 66, 0.16);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .permission_subtext {
        span {
          color: $grayDark;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 1.8rem;
          letter-spacing: -0.14px;
        }

        span:last-child {
          color: $grayDark;
          font-size: 1.4rem;
          font-weight: 600;
          line-height: 1.8rem;
          letter-spacing: -0.14px;
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-decoration-skip-ink: none;
          text-decoration-thickness: auto;
          text-underline-offset: auto;
          text-underline-position: from-font;
        }
      }

      .map_button {
        color: $primary;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: -0.16px;
        padding: 0;
        outline: none;
        border: none;
        margin-left: 1.2rem;
        background-color: transparent;
      }
    }

    .select_address_holder {
      margin-top: 2.4rem;
    }
  }
}

.centered_mark {
  position: absolute;
  top: calc(50% - 2.9rem);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}