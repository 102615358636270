@import 'src/app/styles/assets/scss/colors';

.main_wrapper {
  overflow: auto;
  height: 100%;
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows:
    [headerTop] max-content [headerBottom] 0
    [listTop] auto [listBottom];
  height: 100%;
  overflow: hidden;

  .main_header {
    grid-row: headerTop / headerBottom;
    position: sticky;
    top: 0;
    z-index: 101;
    background-color: $white;
    padding-bottom: 0.4rem;

    .search_holder {
      display: flex;
      padding: 0.2rem 1.6rem;
    }
  }
}
