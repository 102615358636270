@import "src/app/styles/assets/scss/colors";

.wrapper {
  position: absolute;
  bottom: 0;
  top: 0;
  background-color: $white;
  padding-top: var(--sait);
  padding-bottom: var(--saib);
  z-index: 100;

  .content {
    display: grid;
    grid-gap: 2.4rem;
    padding-bottom: 2.4rem;
  }

  .image {
    height: 16rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}