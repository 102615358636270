@import "app/styles/assets/scss/colors";
@import "app/styles/assets/scss/variables";

.photos_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  padding-bottom: 2.4rem;

  .odd_photos {
    position: relative;
    display: flex;

    img {
      display: block;
      box-sizing: border-box;
      width: 9.6rem;
      height: 9.6rem;
      border-radius: 18rem;
      background-color: gray;
      border: 2px solid $error;
    }

    .element {
      box-sizing: border-box;
      position: absolute;
      font-family: $iconFont2;
      font-size: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      bottom: -1.6rem;
      left: 50%;
      background-color: $error;
      color: $white;
      transform: translateX(-50%);
      border-radius: 50%;
      border: 2px solid $white;
    }
  }

  .middle_photo {
    position: relative;
    display: flex;

    img {
      display: block;
      box-sizing: border-box;
      width: 12rem;
      height: 12rem;
      border-radius: 24rem;
      background-color: gray;
      border: 2px solid $success;
    }

    .element {
      box-sizing: border-box;
      position: absolute;
      font-family: $iconFont2;
      font-size: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      bottom: -1.6rem;
      left: 50%;
      background-color: $success;
      color: $white;
      transform: translateX(-50%);
      border-radius: 50%;
      border: 2px solid $white;
    }
  }
}