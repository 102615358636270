@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.link {
  color: $primary;
}

.skeleton_wrapper {
  display: grid;
  grid-gap: 2.4rem;
}

.close_button {
  font-family: $iconFont2;
  color: $black;
  font-size: 2rem;
  line-height: 1;
}

.close_button_hidden {
  display: none;
}