@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.inApp_content_wrapper {
  display: grid;
  padding: 0 1.6rem;
  margin: 0 -1.6rem;

  .inApp_media_list {
    margin: -.8rem 0 2.4rem 0;
    padding-top: .8rem;
    overflow-y: hidden;
    display: flex;
    justify-content: start;
    overflow-x: auto;
    height: 9.6rem;

    li:first-child {
      margin-left: 1.6rem;
    }

    li:last-child {
      margin-right: 1.6rem;
    }

    > li:not(:last-child) {
      margin-right: .8rem;
    }

    li {
      position: relative;
    }

    img {
      box-sizing: border-box;
      border: 1px solid $grayLighter;
      border-radius: 12px;
      background-color: $grayLighter;
      object-fit: cover;
    }

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    .media_percent_holder {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.1rem;
      letter-spacing: -0.16px;
      background: linear-gradient(0deg, rgba(43, 43, 43, 0.40) 0%, rgba(43, 43, 43, 0.40) 100%);
      z-index: 2;
    }

    .upload_error_holder {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      background-color: $grayDarker;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      .media_error {
        color: $white;
        font-size: 1.6rem;
        line-height: 1;
      }
    }

    .video_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.6rem;
      height: 3.6rem;
      border-radius: 50%;
      z-index: 1;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 50%;
        z-index: -1;
      }

      &::before {
        position: relative;
        content: "play";
        font-family: $iconFont2;
        color: $white;
        font-size: 1.6rem;
        line-height: 1;
        z-index: 1;
      }
    }
  }
}

.remove_media {
  outline: none;
  font-family: $iconFont2;
  position: absolute;
  font-size: 1.2rem;
  line-height: 1;
  width: 2.4rem;
  height: 2.4rem;
  background-color: $errorLightrest1;
  border-radius: 50%;
  border: none;
  top: -4px;
  right: -4px;
  color: $errorDark;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
