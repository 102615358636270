@import "app/styles/assets/scss/colors";

.country_select {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
}

.footer_holder {
  display: grid;
  grid-gap: 1.2rem;
}

.inApp_content_holder {
  display: grid;
  padding: 0 1.6rem;
}

.wrapper {
  z-index: 9998;
  background-color: $white;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  padding-bottom: var(--saib);
  padding-top: var(--sait);
}