.page_wrapper {
  display: grid;
  grid-gap: 2.4rem;
  padding-right: 1.6rem;
  padding-top: 0;
  padding-bottom: calc(var(--saib) + 4rem);
  padding-left: 1.6rem;
  overflow-y: auto;
  overflow-x: hidden;
  grid-row: listTop / listBottom;

  > div {
    display: grid;
    grid-gap: 1.2rem;
  }
}
