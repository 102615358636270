.content {
  display: grid;
  grid-gap: 2.4rem;
  margin-bottom: 2.4rem;

  .content_illustration {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16rem;
  }
}
