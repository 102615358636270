@import "src/app/styles/assets/scss/variables";
@import "src/app/styles/assets/scss/colors";


.footer {
  display: grid;
  grid-gap: 1.2rem;

  color: $grayDark;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.9rem;
}

.period_plug {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .plug_content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .plug_content_text {
      width: calc(100vw - 3.2rem);
      display: grid;
      grid-gap: .4rem;
      margin-bottom: 1.2rem;
    }

    .plug_content_title {
      text-align: center;
      color: $black;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.2rem;
    }

    .plug_content_description {
      text-align: center;
      color: $grayDark;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.9rem;
    }
  }
}