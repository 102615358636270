@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.footer {
  display: grid;
  grid-gap: 2.4rem;

  .footer_loading {
    display: grid;
    grid-gap: 2.4rem;
  }
}

.text_block {
  display: grid;
  grid-gap: .4rem;

  .title_text {
    color: $black;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.2rem;
  }

  .description_text {
    color: $grayDark;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.9rem;
  }
}

.priceHolder {
  padding: 1rem 1.2rem;
  background-color: $grayLightrest1;
  border-radius: $borderRadius;
  display: grid;
  text-align: center;

  .price {
    color: $black;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 2rem;
    font-weight: 800;
    line-height: 2.8rem;
  }

  .text {
    color: $gray;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.4rem;
  }
}

.centered_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 3.2rem);
  padding: 0 1.6rem;

  .children_wrapper {
    display: grid;
    justify-content: center;
    grid-gap: 1.2rem;

    button {
      justify-self: center;
    }
  }
}

.loading {
  display: grid;
  grid-gap: 1.2rem;
  justify-self: center;
  text-align: center;
  background-color: $white;

  svg {
    g {
      g {
        g {
          path {
            stroke: $gray;
          }
        }
      }
    }
  }
}
