.empty_services_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-gap: 1.2rem;
  text-align: center;
}