@import "src/app/styles/assets/scss/colors";

.search {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background-color: $white;
  border-radius: 2.4rem 2.4rem 0 0;
  padding: 2.4rem 1.6rem 0 1.6rem;

  .search_wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input_holder {
      display: flex;
      width: 100%;
    }

    .geolocation_button {
      position: absolute;
      right: 0;
      top: -10rem;
      padding: 1.4rem;
      z-index: 3;
      border-radius: 50rem;
      background-color: $white;
      box-shadow: 0 2px 4px 0 rgba(37, 39, 66, 0.16);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .permission_subtext {
      span {
        color: $grayDark;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.8rem;
        letter-spacing: -0.14px;
      }

      span:last-child {
        color: $grayDark;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.8rem;
        letter-spacing: -0.14px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
      }
    }

    .map_button {
      color: $primary;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: -0.16px;
      padding: 0;
      outline: none;
      border: none;
      margin-left: 1.2rem;
      background-color: transparent;
    }
  }

  .select_address_holder {
    margin-top: 2.4rem;
  }
}

.suggests_wrapper {
  list-style: none;
  padding: 0 1.6rem 0 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 10.1rem);
  align-items: flex-start;
  margin-right: -1.6rem;

  li {
    width: 100%;
  }

  li:not(:last-child) {
    margin-bottom: 2.4rem;
  }
}