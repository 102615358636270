@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.wrapper {
  width: calc(100% - 3.2rem);
  height: calc(100% - 1.6rem);
  overflow-y: scroll;
  padding: .8rem 1.6rem;
}

.content_holder {
  display: grid;
  flex-direction: column;
  grid-gap: 1.2rem;
}

.header_button {
  font-family: $iconFont2;
  font-size: 2.4rem;
  line-height: 1;
}

.tabs {
  margin-bottom: 0;

  ul {
    margin: 0 !important;
  }
}

.show_more {
  margin-top: .4rem;
  padding: .8rem 1.6rem;
  color: $primary;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.7rem;
}

.container_reviews {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.container_tabs {
  display: flex;
  gap: 1.2rem;
}

.no_reviews {
  color: $grayDark;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.9rem;
}