@import "src/app/styles/assets/scss/colors";

.phone_prefix {
  display: flex;
  align-items: center;
}

.phone_prefix {
  display: flex;
  align-items: center;

  img {
    margin-right: .4rem;
    width: 2rem;
    height: 2rem;
  }

  div {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.1rem;
    letter-spacing: -0.16px;
  }
}

.content {
  display: grid;
  flex-direction: column;
  grid-gap: 2.4rem;
  margin-bottom: 2.4rem;
}