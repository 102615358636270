@import "src/app/styles/assets/scss/colors";

.sms_inApp_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.6rem 0 1.6rem;
}

.sms_inApp_header {
  color: $black;
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.8rem;
}

.sms_inApp_inputs {
  display: flex;
  flex-direction: column;
  gap: .4rem;
  padding-bottom: 2.4rem;
}

.sms_inApp_text {
  color: $grayDark;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.7rem;
}

.loading_background {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: $black;
  opacity: .4;
  z-index: 99999999;
}

.loading_holder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 1.2rem;
  display: grid;
  grid-gap: .8rem;
  border: 1px solid $grayLighter;
  border-radius: 1.2rem;
  box-sizing: border-box;
  background-color: $white;
  opacity: 1 !important;
  z-index: 99999999;

  div {
    align-self: center;
    justify-self: center;

    svg {
      g {
        g {
          g {
            path {
              stroke: $gray;
            }
          }
        }
      }
    }
  }

  div:nth-child(2) {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4rem;
    color: $black;
  }
}