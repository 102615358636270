@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.content {
  display: grid;
  grid-gap: 2.4rem;
  margin-bottom: 2.4rem;

  .specialist_info {
    display: grid;
    grid-gap: 1.6rem;
    margin: 0 auto;

    .avatar_holder {
      justify-self: center;
      width: 16rem;
      height: 16rem;
      border-radius: 32rem;
      border: 1px solid $grayLightrest1;
      background-color: $grayLightrest2;

      & img {
        width: 16rem;
        height: 16rem;
        border-radius: 32rem;
      }
    }

    .text_info {
      display: grid;
      grid-gap: .4rem;

      .text_info_top {
        overflow: hidden;
        color: $black;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 2rem;
        font-weight: 800;
        line-height: 2.8rem;
      }

      .text_info_bottom {
        align-items: center;
        justify-content: center;
        display: flex;
        color: $black;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.7rem;

        .rating {
          font-family: $iconFont2;
          margin-right: .4rem;
          font-size: 1.2rem;
          line-height: 1;
          color: $warning;
        }
      }
    }
  }
}


.footer {
  display: grid;
  align-self: end;
  grid-gap: .8rem;
}