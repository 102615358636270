@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.layout {
  display: grid;
  grid-row: headerTop / headerBottom;

  &.has_shadow {
    position: relative;
    z-index: 1;
    box-shadow: 0 30px 8px 0 rgba(43, 43, 43, 0.00), 0 19px 8px 0 rgba(43, 43, 43, 0.00), 0 11px 7px 0 rgba(43, 43, 43, 0.02), 0 5px 5px 0 rgba(43, 43, 43, 0.03), 0 1px 3px 0 rgba(43, 43, 43, 0.03);
  }

  .children_holder {
    padding: 0 1.6rem 1.2rem 1.6rem;
    background-color: $white;
    z-index: 5;
  }

  .header_info_holder {
    display: grid;
    column-gap: 1.6rem;
    grid-template-columns:  2rem 1fr  2rem;
    padding: .8rem 1.6rem;

    &.has_second_icon {
      grid-template-columns: 2rem 1fr 2rem 2rem;
    }

    .iconHolder {
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: 4.2rem;
      justify-content: center;

      .title {
        height: 2.2rem;
        color: $black;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        letter-spacing: -0.16px;
      }

      .subtitle {
        margin-top: .3rem;
        overflow: hidden;
        color: $grayDark;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        height: 1.7rem;
      }
    }
  }
}

.step_holder {

  &.has_shadow {
    position: relative;
    z-index: 1;
    box-shadow: 0 30px 8px 0 rgba(43, 43, 43, 0.00), 0 19px 8px 0 rgba(43, 43, 43, 0.00), 0 11px 7px 0 rgba(43, 43, 43, 0.02), 0 5px 5px 0 rgba(43, 43, 43, 0.03), 0 1px 3px 0 rgba(43, 43, 43, 0.03);
  }
}

.loading_wrapper {
  display: flex;
  flex: 1 1 0;
  height: 4.2rem;
  align-items: center;
  justify-content: center;
}