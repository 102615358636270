@font-face {
  font-family: 'Naimi-icons-2';
  src: url('./vocfont.woff2') format('woff2'),
  url('./vocfont.woff') format('woff'),
  url('./vocfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-size: 1.4rem;
  line-height: 1;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
  font-family: "Naimi-icons-2" !important;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

$iconFont2: "Naimi-icons-2";