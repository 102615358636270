@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.wrapper {
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: .4rem;
  }

  .label_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .2rem .6rem;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.4rem;
    background-color: $grayLighter;
    color: $grayDark;

    &.success {
      background-color: $successLightrest1;
      color: $successDark;
    }

    &.error {
      background-color: $errorLightrest1;
      color: $errorDark;
    }

    &.primary {
      background-color: $primaryLightrest1;
      color: $primaryDark;
    }

    &.accent_1 {
      background-color: $accent1Lightrest1;
      color: $accent1Dark;
    }

    &.accent_2 {
      background-color: $accent2Lightrest1;
      color: $accent2Dark;
    }

    &.accent_3 {
      background-color: $accent3Lightrest1;
      color: $accent3Dark;
    }

    &.grey {
      background-color: $grayLighter;
      color: $grayDark;
    }

    &.freedom_1 {
      background: linear-gradient(278deg, rgba(70, 200, 224, 0.10) -29.33%, rgba(82, 216, 120, 0.10) 123.73%), #FFF;
    }

    &.freedom_2 {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 54.69%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 50%), linear-gradient(262deg, #01D48A -1.12%, #006FC2 101.31%);
      color: $white;
    }

    .text {
      &.freedom_1 {
        background: var(--Gradient-freedom-cashback, linear-gradient(262deg, #01D48A -1.12%, #006FC2 101.31%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .label_icon {
      font-family: $iconFont2;
      font-size: .8rem;
      line-height: 1;
    }
  }
}