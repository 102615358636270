@import "src/app/styles/assets/scss/colors";

.footer_wrapper {
  display: grid;
  grid-gap: 1.2rem;

  .copy_code_wrapper {
    display: grid;
    padding: 1rem 1.2rem;
    background-color: $grayLightrest1;
    border-radius: 1.2rem;
    justify-content: center;

    color: $black;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 2rem;
    font-weight: 800;
    line-height: 2.8rem;

    span:nth-child(2) {
      color: $gray;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.4rem;
    }
  }
}

