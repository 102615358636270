@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  color: $black;

  .header {
    font-size: 1.6rem;
    font-weight: 800;
    line-height: 2.2rem;
    margin-bottom: 1.2rem;
  }

  .empty_text {
    color: $grayDark;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.9rem;
    margin-bottom: 1.2rem;
  }

  .media_holder {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 15rem), 1fr));
    grid-gap: .8rem;
    padding-left: 0;
    margin: 0 0 1.2rem 0;

    li {
      display: flex;
      height: 14rem;
    }

    list-style: none;
  }

  .empty {
    color: $grayDark;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.9rem;
  }
}

.skeleton_media {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: .8rem;
  margin-bottom: 1.2rem;
}