@import "src/app/styles/assets/scss/colors";

.list_item {
  position: relative;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .list_item_labels {
    position: absolute;
    top: 1rem;
    left: 1rem;
    flex-direction: column;

    >div {
      margin: 0;
    }

    div:not(:first-child) {
      margin-top: .4rem;
    }
  }

  img {
    border-radius: 1.6rem;
    height: 9.6rem;
    width: 100%;
    object-fit: cover;
  }

  .list_item_text {
    margin-top: .4rem;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0;
    color: $black;
    align-self: start;

    display: -moz-box !important;
    -moz-box-orient: vertical;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;

    .last_word {
      color: $errorDark;
    }
  }

  .list_with_subtitle {
    margin-top: .4rem;
    align-self: flex-start;
    display: grid;
    align-items: flex-start;

    span {
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0;
      color: $black;
      align-self: start;
      display: -moz-box !important;
      -moz-box-orient: vertical;
      display: -webkit-box !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .subtitle {
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0;
      color: $successDark;

      &.is_ultra {
        color: $errorDark;
      }
    }
  }
}