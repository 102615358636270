@import "src/app/styles/assets/scss/colors";

.promo_block {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.2rem;
  border-radius: 1.6rem;
  padding: 1.6rem;
  background-color: $grayLightrest1;
  align-items: center;

  .text_block {
    display: grid;
    grid-gap: .4rem;

    .title {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.1rem;
      letter-spacing: -0.2px;
      color: $black;
    }

    .description {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.8rem;
      letter-spacing: -0.1px;
      color: $grayDark;
    }
  }
}