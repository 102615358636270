@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.info_holder {
  display: grid;
  grid-gap: 1.6rem;
  align-items: center;
  justify-content: center;
  background-color: $grayLightrest1;
  margin: -2.4rem -1.6rem;
  padding: 2.4rem 0 4.8rem 0;

  .info_content_wrapper {
    display: grid;
    grid-gap: 1.6rem;

    .info_content_photo {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: 13.6rem;

      &.client_view {
        height: 12rem;
      }

      .svg_avatar_holder {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 12rem;
        height: 12rem;
        border-radius: 50%;
        background-color: $white;

        img {
          width: 6.4rem;
          height: 6.4rem;
          border-radius: 0;
          background-color: $white;
        }
      }

      .img_wrapper {
        width: 12rem;
        height: 12rem;
        border-radius: 50%;
        background-color: $white;
      }

      img {
        width: 12rem;
        height: 12rem;
        border-radius: 50%;
        background-color: $grayLightrest1;
      }

      .avatar_backdrop {
        position: absolute;
        width: 12rem;
        height: 12rem;
        border-radius: 50%;
        background-color: rgba($black, .4);

        .avatar_on_moderation {
          position: absolute;
          color: $white;
          text-align: center;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 2.2rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 10.7rem;
        }
      }

      .info_content_button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        font-family: $iconFont2;
        background-color: $primary;
        border: 2px solid $grayLightrest1;
        padding: .8rem;
        outline: none;
        border-radius: 2rem;
        color: $white;
        font-size: 1.6rem;
        line-height: 1;
        transition: background-color .5s ease-out;

        &:active {
          background-color: $primaryDark;
        }
      }
    }

    .labels {
      justify-self: center;
    }

    .info_content_name {
      display: grid;
      grid-gap: .4rem;

      .name {
        overflow: hidden;
        color: $black;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 2rem;
        font-weight: 800;
        line-height: 2.8rem;
      }

      .info_content_rating {
        display: flex;
        justify-content: center;
        align-items: center;

        span:nth-child(1) {
          color: $warning;
        }

        .newbie {
          color: $black;
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.7rem;
        }

        .rating {
          display: flex;
          align-items: center;

          span:nth-child(1) {
            color: $black;
            margin-right: .2rem;
          }

          color: $gray;
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.7rem;
        }
      }
    }
  }
}

.skeleton_rating {
  display: grid;
  grid-template-columns: repeat(2, 6rem);
  grid-gap: .2rem;
  align-items: center;
  justify-content: center;
}

.skeleton_add_photo {
  position: absolute;
  bottom: 0;
  border: 2px solid $grayLightrest1;
  border-radius: 20px;
}

.inApp_buttons {
  display: grid;
  grid-gap: .8rem;
}