.radio_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 0 1.6rem 2.4rem 1.6rem;
}

.radio_items {
  display: grid;
  flex-direction: column;
  gap: 2.4rem;
  grid-gap: 2.4rem;
  touch-action: none;
}