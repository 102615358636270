@import "src/app/styles/assets/scss/colors";

.wrapper {
  display: grid;
  grid-gap: 1.2rem;
  color: $black;

  .header {
    font-size: 1.6rem;
    font-weight: 800;
    line-height: 2.2rem;
  }

  .tabs {
    margin-bottom: 0;

    ul {
      margin: 0;
    }
  }

  .empty {
    color: $grayDark;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.9rem;
  }
}

.skeleton_tabs {
  display: grid;
  grid-template-columns: repeat(4, 8rem);
  grid-gap: .2rem;
  overflow-y: hidden;
}