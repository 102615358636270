@import "app/styles/assets/scss/colors";
@import "app/styles/assets/scss/variables";

.wrapper {
  height: calc(100% - 1.6rem);
  display: flex;
  padding: .8rem 1.6rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader_spinner {
  margin-bottom: 1.2rem;
  width: 4rem;
  height: 4rem;

  svg {
    g {
      g {
        g {
          path {
            stroke: $gray;
          }
        }
      }
    }
  }
}

.title {
  color: $black;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
  margin-bottom: .4rem;
}

.description {
  color: $grayDark;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.9rem;
}