@import "app/styles/assets/scss/colors";

.wrapper {
  display: grid;
  grid-gap: 2.4rem;

  .prices {
    display: grid;
    grid-template-columns: 1fr 2.4rem;

    .prices_info {
      display: grid;
      grid-gap: .4rem;
      color: $black;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.2rem;

      span:nth-child(2) {
        color: $grayDark;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.9rem;
      }
    }

    .toggle_icon {
      color: $gray;
    }
  }

  .prices_content {
    overflow: hidden;
    transition: max-height .4s cubic-bezier(0.4, 0, 0.2, 1);
    display: grid;
    grid-gap: 2.4rem;
    margin-bottom: 2.4rem;

    .subtext {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

.input_prefix {
  margin-right: .8rem;
  color: $black;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.2rem;
}

.input_suffix {
  @extend .input_prefix;
  margin-right: 0;
  margin-left: .8rem;
}

.skeleton_input {
  display: grid;
  grid-gap: .4rem;
}

.inApp_buttons {
  display: grid;
  grid-gap: .8rem;
}