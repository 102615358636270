@import "src/app/styles/assets/scss/variables";
@import "src/app/styles/assets/scss/colors";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 1.6rem);
  width: calc(100vw - 3.2rem);
  padding: .8rem 1.6rem;

  .content {
    display: grid;

    .watch_icon {
      font-family: $iconFont2;
      font-size: 4rem;
      line-height: 1;
      color: $gray;
      text-align: center;
      margin-bottom: 1.2rem;
    }

    .content_text {
      .text_title {
        color: $black;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.2rem;
      }

      display: grid;
      grid-gap: .4rem;
      text-align: center;
      margin-bottom: 1.2rem;
    }
  }
}