@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.layout {
  display: grid;
  grid-template-rows: 1.8rem 10.4rem auto;
  padding-bottom: 2.4rem;
  margin: 0 -1.6rem;

  .title_holder {
    display: flex;
    margin-left: 1.6rem;

    .title {
      color: $black;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: -0.14px;
      margin-right: .8rem;
    }
  }

  .media_wrapper {
    display: flex;
    justify-content: start;
    overflow-x: auto;
    overflow-y: hidden;

    list-style: none;
    margin: 0;
    padding: .8rem 0 0 0;

    > li:not(:last-child) {
      margin-right: .8rem;
    }

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    li:first-child {
      margin-left: 1.6rem;
    }

    li:last-child {
      margin-right: 1.6rem;
    }

    li {
      position: relative;
      width: 10.9rem;
    }

    .upload_button {
      width: 10.9rem;
      height: 9.6rem;
      border: 1px dashed $gray;
      background-color: $grayLightrest1;
      outline: none;
      border-radius: 1.2rem;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .add_icon {
        display: block;
        width: 3.6rem;
        height: 3.6rem;
        border-radius: 50%;
        background-color: $grayLighter;
        position: relative;

        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: "add";
          font-family: $iconFont2;
          font-size: 1.6rem;
          color: $grayDark;
        }
      }
    }
  }

  .description {
    margin-left: 1.6rem;
    color: $grayDark;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 130%;
    padding-top: .8rem;
  }
}

.remove_media {
  outline: none;
  font-family: $iconFont2;
  position: absolute;
  font-size: 1.2rem;
  line-height: 1;
  width: 2.4rem;
  height: 2.4rem;
  background-color: $errorLightrest1;
  border-radius: 50%;
  border: none;
  top: -4px;
  right: -4px;
  color: $errorDark;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}