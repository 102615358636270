@import "src/app/styles/assets/scss/colors";

.content_wrapper {
  background-color: $grayLightrest1;
  padding: 0 1.6rem 0 1.6rem;
  margin: 0 -1.6rem;
  display: grid;

  .content {
    display: grid;
    grid-gap: 2.4rem;
    background-color: $white;
    margin: 0 -1.6rem;
    padding: 2.4rem 1.6rem 2.4rem 1.6rem;
    border-radius: 2.4rem 2.4rem 0 0;

    .header {
      font-size: 1.6rem;
      font-weight: 800;
      line-height: 2.2rem;
    }

    .empty_reviews {
      color: $grayDark;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.9rem;
    }
  }
}

.skeleton_reviews {
  display: grid;
  grid-gap: 2.4rem;
}