@import "src/app/styles/assets/scss/variables";
@import "src/app/styles/assets/scss/colors";

.inApp_wrapper {
  display: grid;
  flex-direction: column;
  justify-content: center;
  grid-auto-columns: 1fr;
  padding: 0 1.6rem;
  grid-gap: 2.4rem;
}

.inApp_image {
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.inApp_buttons {
  display: grid;
  grid-gap: .8rem;
}

.close_icon {
  font-family: $iconFont2;
  font-size: 2rem;
  line-height: 1;
  color: $black
}