@import "src/app/styles/assets/scss/colors";

.info_list_wrapper {
  display: grid;

  .bonuses_info_list {
    display: flex;
    overflow-x: auto;
    margin: 0 -1.6rem 2.4rem -1.6rem;
    padding: 0 1.6rem;

    > *:not(:last-child) {
      margin-right: 1.2rem;
    }

    flex-wrap: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    .info_card_wrapper {
      display: flex;
      flex-direction: column;
      padding: 1.2rem;
      background-color: $grayLightrest1;
      border-radius: 1.2rem;
      color: $black;
      min-width: 25rem;

      .info_card_title {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.2rem;
        height: 2.2rem;
        margin-bottom: .8rem;
      }

      .info_card_description {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.9rem;
      }
    }
  }
}

.content_header {
  color: $black;
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 2.2rem;
  margin-bottom: 1.2rem;
}