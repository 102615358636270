@import "src/app/styles/assets/scss/colors";

.alert_wrapper {
  position: absolute;
  display: grid;
  grid-gap: 1.2rem;
  align-items: center;
  text-align: center;
  top: 50%;
  left: 50%;
  width: calc(100vw - 3.2rem);
  padding: 0 1.6rem;
  transform: translate(-50%, -50%);

  .alert_icon {
    color: $gray;
    font-size: 4rem;
  }

  button {
    justify-self: center;
  }
}