@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.wrapper {
  background-color: $black;
  color: $white;
  border-radius: 100px;
  padding: .8rem 1.6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 1.6rem;
  right: 1.6rem;
  z-index: 99999999999 !important;
  height: 4rem;
  margin-bottom: var(--saib);

  &.remove_type, &.demo_login {
    padding: .8rem 1.6rem .8rem .8rem;
    justify-content: space-between;
  }

  .icon {
    font-family: $iconFont2;
    font-size: 1.6rem;
    line-height: 1;
    margin-right: .8rem;
  }

  .text {
    overflow: hidden;
    color: $white;
    text-overflow: ellipsis;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6rem;

    &.remove_type, &.demo_login {
      display: flex;
      flex: 1 1 0;
      margin-left: 1.2rem;
    }
  }

  .close_icon {
    font-size: 1.6rem;
    line-height: 1;
    color: $white;
  }
}