@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.wrapper {
  padding: 1.6rem;
  background-color: $grayLightrest1;
  grid-gap: 1.2rem;
  border-radius: $borderRadius;
  display: grid;
  flex-direction: column;
}

.header {
  display: flex;
  gap: 1.2rem;
  align-items: center;
}

.header_left {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}

.header_left_name {
  color: $black;
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 2.2rem;
}

.header_left_date {
  color: $grayDark;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.7rem;
}

.review_text {
  //max-height: 5.7rem;
  color: $black;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.9rem;
  word-break: break-word;
}

.not_show {
  max-height: 5.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
}

.show {
  max-height: 100%;
  overflow: visible;
  text-overflow: clip;
  display: block;
  -moz-box-orient: unset;
  -webkit-box-orient: unset;
  -webkit-line-clamp: unset;
  line-clamp: unset;
}

.has_more {
  margin-top: .4rem;
  color: $primary;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.7rem;
}

.media_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 6rem);
  grid-gap: .4rem;
  padding-left: 0;
  margin: 0;
  list-style: none;

  li {
    display: flex;
    height: 6rem;
    position: relative;

    img {
      border-radius: .4rem !important;
      height: 6rem !important;
    }
  }
}

.not_approved_text {
  color: $grayDark;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.9rem;
}

.name_block {
  color: $black;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.9rem;
}

.label {
  > * {
    color: $grayDark;
  }
}
