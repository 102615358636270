@import "src/app/styles/assets/scss/colors";

.wrapper {
  display: grid;
  grid-gap: 1.2rem;
  color: $black;

  .header {
    font-size: 1.6rem;
    font-weight: 800;
    line-height: 2.2rem;
  }

  .text_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .text {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.9rem;
      word-break: break-word;

      &.without_text {
        color: $grayDark;
      }

      &.moderated {
        color: #ADADC2;
      }
    }
  }
}

.skeleton_text {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-gap: .4rem;
}

.label {
  margin-bottom: 1.2rem;

  > * {
    color: $grayDark;
  }
}