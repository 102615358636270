@import "src/app/styles/assets/scss/colors";

.centered_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 3.2rem);
  padding: 0 1.6rem;

  .children_wrapper {
    display: grid;
    justify-content: center;
    grid-gap: 1.2rem;

    button, span {
      justify-self: center;
    }
  }
  .text_block {
    display: grid;
    grid-gap: .4rem;

    .title_text {
      color: $black;
      text-align: center;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.2rem;
    }

    .description_text {
      color: $grayDark;
      text-align: center;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.9rem;
    }
  }
}