@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";


.wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-gap: .8rem;
  justify-content: start;
  overflow-x: auto;

  list-style: none;
  margin: -2.4rem 0 0 0;
  padding: 2.4rem 0 0 0;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  li:not(:first-child) {
    border: 1px solid $grayLighter;
  }

  li {
    position: relative;
    border-radius: 1.2rem;

    .delete_button {
      position: absolute;
      width: 2.4rem;
      height: 2.4rem;
      background-color: $error;
      border-radius: 6rem;
      right: -.4rem;
      top: -.4rem;
      font-family: $iconFont2;
      font-size: 1.2rem;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      z-index: 2;
    }

    img {
      height: 100% !important;
      margin: 0 auto;
    }
  }
}

.add_button {
  box-sizing: border-box;
  display: grid;
  grid-gap: .4rem;
  width: 9.2rem;
  height: 9.2rem;
  border: 1px dashed $primary;
  background-color: $primaryLightrest2;
  border-radius: 12px;
  padding: 1.7rem 1.8rem;
  transition: background-color .5s ease-out;

  &.disabled {
    border-color: $grayLighter;
    background-color: $grayLightrest2 !important;

    > * {
      color: $gray !important;
    }
  }

  &:active {
    background-color: $primaryLighter;
  }

  .add_button_icon {
    display: inline-flex;
    justify-self: center;
    font-size: 2rem;
    color: $primary;
  }

  .add_button_text {
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.7rem;
    color: $primary;
  }
}

.textarea_wrapper {
  margin: 2.4rem 0;
  display: grid;
  grid-gap: .8rem;

  .textarea_title {
    color: $black;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.9rem;
  }
}