@import "src/app/styles/assets/scss/colors";

.wrapper {
  display: grid;
  grid-template-columns: 2rem 1fr 2rem;
  grid-gap: 1.6rem;
  padding: 1.4rem 1.6rem;
  align-items: center;
  width: calc(100% - 3.2rem);

  > * {
    z-index: 1;
    color: $white;
  }

  .title {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.2rem;
    letter-spacing: -0.2px;
    text-align: center;
    padding: 1rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
