@import "app/styles/assets/scss/colors";

.accordion_wrapper {
  padding: 1.6rem;
  background-color: $grayLightrest1;
  border-radius: 1.6rem;
  margin: 0;
  display: grid;
  grid-gap: 2rem;

  .title_holder {
    display: grid;
    grid-template-columns: 1fr 2rem;
    column-gap: 1.2rem;
    align-items: center;

    .title {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.1rem;
      color: $black;
      margin-bottom: .4rem;
      letter-spacing: -0.2px;
    }

    .content {
      overflow: hidden;
      transition: max-height .4s cubic-bezier(0.4, 0, 0.2, 1);

      color: $grayDark;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.8rem;
      letter-spacing: -0.1px;
    }
  }

  .toggle_button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.toggle_button_open {
      transform: rotate(180deg);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}