.current_tariff_tabs {
  margin-top: 1.2rem;
  display: flex;
  justify-content: flex-start;

  flex-wrap: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}