@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.wrapper {
  height: calc(100vh - 1.6rem);
  display: flex;
  padding: .8rem 1.6rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon_holder {
  font-family: $iconFont2;
  font-size: 4rem !important;
  line-height: 1 !important;
  color: $error;
  margin-bottom: 1.2rem;
}

.title {
  color: $black;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
  margin-bottom: .4rem;
}

.description {
  color: $grayDark;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.9rem;
}

.button {
  margin-top: 1.2rem;
}