@import "src/app/styles/assets/scss/colors";

.navigation_wrapper {
  position: relative;
  margin: 0;
  padding: 0 1.6rem;
  display: flex;
  justify-content: flex-start;
  margin-top: .6rem;

  flex-wrap: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  list-style: none;

  li:not(:last-of-type) {
    margin-right: .8rem;
  }

  &::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .skeleton_item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: .8rem 0;
    min-width: 7.2rem;
    width: auto;
    flex-shrink: 0;
  }

  .navigation_item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: .8rem 0;
    min-width: 7.2rem;
    width: auto;
    flex-shrink: 0;

    &.is_active {
      > * {
        color: $black !important;
      }

      .item_text {
        font-weight: 700;
      }
    }

    &.is_active_ultra {
      span {
        color: $errorDark !important;
      }

      .item_text {
        font-weight: 700;
      }
    }

    .item_text {
      display: inline-flex;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0;
      color: $grayDark;
      white-space: nowrap;
    }
  }

  .scroll_line {
    position: absolute;
    height: 0.2rem;
    bottom: 0;
    background-color: $grayLight;
    margin: 0 -1.6rem;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      height: 0.2rem;
      background-color: $black;
      transition: transform 0.3s ease, width 0.3s ease;
    }
  }

  .active_scroll_line {
    position: absolute;
    height: 0.2rem;
    bottom: 0;
    background-color: $black;
    transition: transform 0.4s ease, width 0.4s ease;
  }
}

.skeleton {
  align-items: center;
  padding: .8rem 0;
  min-width: 7.2rem;
  width: auto;
  flex-shrink: 0;

  span:nth-child(2) {
    margin-top: .8rem;
  }

  > * {
    text-align: center;
  }
}